import React from 'react';
import Helmet from 'react-helmet';
import SingleColPageLayout from '../templates/single-col-page-layout';

const HomePage = () => {
return (
  <SingleColPageLayout>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
    <div className="max-w-xl mx-auto">
      <h1>Page Not Found :-(</h1>
      <p>
        You can view the 
        {' '}
        <a href="/articles/">full list of my articles here</a>
        {' '}
or go back to the
        {' '}
        <a href="/">home page</a>
.
      </p>
    </div>
  </SingleColPageLayout>
);
}

export default HomePage;
